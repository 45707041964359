@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@asolRed: #a94442;
@asolLighterRed: #C35658;
@asolBlue: rgb(40, 96, 144);

//body, label, .checkbox label {
//	font-weight: 300;
//}

.nav-tabs > li > a.tabs-alert {
  border-color: #ebccd1 #ebccd1 #666666 #ebccd1;
  color: @asolRed;
  background-color: #f2dede;
}

.nav-tabs > li.active > a.tabs-alert,
.nav-tabs > li.active > a.tabs-alert:hover, .nav-tabs > li.active > a.tabs-alert:focus {
  border-color: @asolRed @asolRed white @asolRed;
  color: @asolRed;
  background-color: #f2dede;
}

label.alloggi_field {
  font-weight: normal !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

@media (min-width: @screen-md-min) {
  div.panel-body.home {
    min-height: 310px;
  }
}

.title {
  font-size: 72px;
  margin-bottom: 40px;
  color: #B0BEC5;
}

.alert-danger-important {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text) !important;
}
.alert-success-important {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text) !important;
}

.notification {
  margin: 0px -15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
  padding: 4px 0px;
}

.notification a {
  color: black;
}

.notification a:hover {
  color: @asolBlue;
}

.popover-content > .notification:last-child, .navbar-collapse > .notification:last-child {
  border-bottom: none;
  padding: 4px 0px 0px;
}

.notification > .row {
  display: table;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.notification > .row > div {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

#notification-icon {
  font-size: 20px;
}

.notification-button {
  background: rgba(255, 255, 255, 0.25);
  text-align: center;
  border-radius: 20px;
  width: 40px;
  margin-top: 5px;
}

.notification-button a {
  padding: 0px !important;
  display: inline !important;
}

.notification-button > a > i {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  line-height: 40px;
  width: 40px;
  font-size: 18px;
}

.notification-counter-small {
  display: inline;
  float: right;
  background: @asolBlue;
  padding: 1px 7px;
  border-radius: 10px;
  color: white;
  margin-right: -50px;
  font-size: 10px;
  margin-top: 30px;
  position: relative;
}

.notification-counter {
  height: 45px;
  margin-top: 5px;
}

.notification-counter > div {
  color: white;
  background: @asolBlue;
  padding: 1px 6px;
  border-radius: 10px;
  margin-top: 26px;
  margin-left: -16px;
  font-size: 10px;
}

#notification-popover div[class^="popover"], div[class*=" popover"] {
  min-width: 350px ! important;
}

#match-table td, #alloggi-table td, #contratti-table td{
  vertical-align: middle;
}
.smallbase(@color){
  opacity:0.8;
  font-size:10px;
  font-weight:900;
  color:@color;
}
span[class~='field-hint']{
  .smallbase(black);
  font-size:12px;
  opacity: 0.5;
  margin-left:10px;
}
span[class~='redbold-sm']{
  .smallbase(@asolRed);
}
span[class~='redbold']{
  .smallbase(@asolRed);
  font-size:12px;
}
span[class~='redbold-lg']{
  .smallbase(@asolRed);
  font-size:14px;
}

.centerTextInCell {
  text-align: center;
}

.panel-asol {
  border-color: @asolRed;
}
.panel-asol > .panel-heading {
  color: white;
  background-color: @asolLighterRed;
  border-color: @asolRed;
}
.panel-asol > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: @asolRed;
}
.panel-asol > .panel-heading .badge {
  color: white;
  background-color: @asolLighterRed;
}

.panel-default > .panel-heading {
  font-size:14px;
  font-weight:900;
  color:@asolRed;
}

.more-control:not(th){
  background: url(../images/more.png) no-repeat center center;
  cursor: pointer;
  width: 18px;
}

td[class~='centerVertical']{
  vertical-align: middle !important;
}

.caret {
  font-size: 0px !important;
}

.zone-element-loop(@i; @zones) when (@i <= length(@zones)) {
  .zone-element-@{i} > a {
    @zone: extract(@zones, @i);
    background: url('/images/zoneMilano/@{i}.png') no-repeat right;
    background-blend-mode: luminosity;
    background-size: 30px;
    padding-right: 6px !important;
    background-origin: content-box;
    height:32px;
  }
  .zone-element-loop((@i+1), @zones);
}
.zone-element(@zones...){
  .zone-element-loop(1, @zones);
}

.zone-element(1,2,3,4,5,6,7,8,9);